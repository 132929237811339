// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "21.4.0",
  "buildNumber": "13469",
  "gitSha": "f3b865c5018dec2bddf98496e0e74e8e70a80cb6",
  "fullVersion": "21.4.0-13469",
  "formattedVersion": "21.4.0 (13469-f3b865c5018dec2bddf98496e0e74e8e70a80cb6)",
  "copyrightYear": "2021"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/21.4.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/21.4.0/${stripLeadingPrefix(suffix, '#')}`
}
    
