/*
 * Copyright 2021 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Notification, SystemNotifications} from "models/notifications/system_notifications";

export class DataSharingCleaner {
  static USAGE_DATA_LAST_REPORTED_TIME_KEY = "last_usage_data_reporting_check_time";

  static clean() {
    DataSharingCleaner.cleanupLocalStorage();
    return DataSharingCleaner.cleanupNotifications();
  }

  static cleanupLocalStorage() {
    localStorage.removeItem(DataSharingCleaner.USAGE_DATA_LAST_REPORTED_TIME_KEY);
  }

  static cleanupNotifications() {
    return SystemNotifications.all().then((allNotifications: SystemNotifications) => {
      allNotifications.remove((m: Notification) => {
        return m.type === "DataSharing" || m.type === "DataSharing_v18.8.0";
      });

      SystemNotifications.setNotifications(allNotifications);
    });

  }
}
